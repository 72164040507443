<template>
	<div class="row">
		<div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-sm-6 col-lg-3 px-1 mb-3">
                     <label><i class="far fa-filter color-theme me-1"></i> {{ $t('admEstatisticas.filtro') }}</label>
                     <v-select title="Desktop" :options="$t('admEstatisticas.filtros')" v-model="pesquisa.tipo" label="value" :reduce="f => f.key" :searchable="false" :clearable="false" @input="pesquisa.valor = null;" />
                     <select title="Mobile" class="form-control" v-model="pesquisa.tipo" @change="pesquisa.valor = null;">
                        <option v-for="(option, index) in $t('admEstatisticas.filtros')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Pacote'">
                     <label><i class="far fa-backpack color-theme me-1"></i> {{ $t('admEstatisticas.pacote') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admEstatisticas.pacoteDesc')" :options="listaPacotes" v-model="pesquisa.valor" label="nome" :reduce="p => p.id">
                        <template v-slot:no-options>{{ $t('admEstatisticas.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in listaPacotes" :key="index" :value="option.id">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Script'">
                     <label><i class="far fa-file-alt color-theme me-1"></i> {{ $t('admEstatisticas.script') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admEstatisticas.scriptDesc')" :options="listaScripts" v-model="pesquisa.valor" label="nome" :reduce="s => s.id">
                        <template v-slot:no-options>{{ $t('admEstatisticas.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in listaScripts" :key="index" :value="option.id">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Dono'">
                     <label><i class="far fa-user color-theme me-1"></i> {{ $t('admEstatisticas.dono') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admEstatisticas.donoDesc')" :options="listaDonosScript" v-model="pesquisa.valor" label="nome" :reduce="d => d.id">
                        <template v-slot:no-options>{{ $t('admEstatisticas.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in listaDonosScript" :key="index" :value="option.id">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Categoria'">
                     <label><i class="far fa-stream color-theme me-1"></i> {{ $t('admEstatisticas.categoria') }}</label>
                     <v-select title="Desktop" :placeholder="$t('admEstatisticas.categoriaDesc')" :options="$t('categorias')" v-model="pesquisa.valor" label="value" :reduce="c => c.key">
                        <template v-slot:no-options>{{ $t('admEstatisticas.semResultados') }}</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.valor">
                        <option v-for="(option, index) in $t('categorias')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-3 px-1 mb-3" v-if="pesquisa.tipo == 'Autor'">
                     <label><i class="far fa-user color-theme me-1"></i> {{ $t('admEstatisticas.autor') }}</label>
                     <input type="text" class="form-control" :placeholder="$t('admEstatisticas.autorDesc')" v-model="pesquisa.valor" maxlength="200" @keyup.enter="buscarVendasStatus" />
                  </div>
                  <div class="col-9 col-lg-4 px-1 mb-3">
                     <label><i class="far fa-calendar color-theme me-1"></i> {{ $t('admEstatisticas.data') }}</label>
                     <datePicker :format="'DD/MM/YYYY'" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-3 col-lg-2 px-1 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarVendasStatus">
								<i class="fas fa-search font-13"></i>
							</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-xl-7 pe-xl-1">
			<div class="card mb-2" v-if="pesquisa.dias.datas.length > 0">
				<div class="card-body p-12">
					<div class="row align-items-center">
						<div class="col-12 mt-2 text-center">
							<h1 class="mb-0 weight-600 font-20"><i class="far fa-chart-bar color-theme me-1 font-16"></i> {{ $t('admEstatisticas.vendasDiarias') }}</h1>
						</div>
						<div class="col-12 mt-3">
							<highcharts class="custom-chart" ref="chart" :options="chartMaisVendidos"></highcharts>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-2" v-if="pesquisa.produtos.length > 0">
				<div class="card-body p-12">
					<div class="row align-items-center">
						<div class="col-12 mt-2 text-center">
							<h1 class="mb-0 weight-600 font-20"><i class="far fa-trophy-alt color-theme me-1 font-16"></i> {{ $t('admEstatisticas.maisVendidos') }}</h1>
						</div>
						<div class="col-12 mt-3">
							<highcharts class="custom-chart" ref="chart" :options="chartPie"></highcharts>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-xl-5 ps-xl-1" v-if="pesquisa.status.resultado.length > 0">
			<div class="card mb-1">
            <div class="card-body p-3">
               <div class="row m-0">
                  <div class="col-12 p-0 mb-1">
                     <div class="row text-center mb-1 weight-700 m-0">
                        <div class="col-6 px-1">
                           <span class="limitador"><i class="far fa-stream me-2 font-13 color-theme"></i>Status</span>
                        </div>
                        <div class="col-6 px-1">
                           <span class="limitador"><i class="fas fa-equals me-2 font-13 color-theme"></i>Total</span>
                        </div>
                     </div>
                  </div>

						<div class="col-12 p-0" v-for="(status, index) in pesquisa.status.resultado" :key="index">
							<div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
								<div class="card-body py-2 px-0">
									<div class="row text-center my-1 mx-0">
										<div class="col-6 px-1"><span class="limitador">{{ status.status }}</span></div>
										<div class="col-6 px-1">
											<span class="limitador">
												<small class="font-10">R$</small> {{ parseFloat(status.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

                  <div class="col-12 mt-2 p-0">
                     <div class="row text-center color-theme weight-700 m-0">
                        <div class="col-6 px-1"><span class="limitador">Total</span></div>
                        <div class="col-6 px-1">
                           <span class="limitador">
                              <small class="font-10">R$</small> {{ parseFloat(pesquisa.status.vendaTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
		</div>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Estatísticas',
	data : function () {
		return {
			pesquisa: {
				'tipo': 'Dono', 'valor': null, 'data': [new Date(), new Date()], 
				'status': {'resultado': [], 'vendaTotal': 0}, 'dias': {'datas': [], 'valores': [], 'quantidades': []}, 'produtos': []
			},
			chartMaisVendidos: {
				chart: {
					type: 'column',
					backgroundColor: 'transparent',
					height: 300,
					accessibility: {
						enabled: false
					},
					animation: true,
				},
				title: {
					text: undefined,
					useHTML: true,
					style: {
						// color: 'var(--dark-color)',
						fontFamily: 'sans-serif'
					}
				},
				xAxis: {
					categories: [],
				},
				yAxis: [{
					labels: {
					format: '{value}',
					},
					title: {
					text: 'Quantidade',
					},
					opposite: false,
					gridLineColor: '#747474'
				},
				{
					labels: {
					format: '{value}',
					},
					title: {
					text: 'Valor',
					},
					opposite: true,
					gridLineColor: '#747474'
				}],
				legend: {
					itemStyle: {
						// color: 'var(--font-color)',
					},
					itemHoverStyle: {
						// color: 'var(--primary-color)',
					},
					zIndex: 2,
				},
				series: [
					{
						yAxis: 0,
						name: "Quantidade",
						data: [],
						// color: 'var(--primary-color)'
					},
					{
						yAxis: 1,
						name: "Valor",
						data: [],
						color: '#FF4C4C',
					},
				],
			},
			chartPie: {
				chart: {
					type: 'pie',
					options3d: {
						enabled: true,
						alpha: 45,
						beta: 0
					},
					backgroundColor: 'transparent',
					height: 280,
					accessibility: {
						enabled: false
					},
					animation: true,
				},
				title: {
					text: undefined,
					style: {
						color: '#ffffff',
						fontFamily: 'sans-serif'
					}
				},
				xAxis: {
					categories: [],
				},
				series: [{
					name: "Vendas",
					data: [],
					dataLabels: {
						style: {
							color: '#ffffff',
							textOutline: '1px 1px 1px 1px #333333',
						},
						formatter: function() {
							return this.point.name + ': <b style="color: #FF4C4C;">' + this.point.y + '</b>';
						},
						distance: 20,
					}
				}],
				plotOptions: {
					pie: {
						innerSize: '40%',
						size: '100%',
						// colors: ['#FFB3B3', '#FF9191', '#FF6E6E', '#FF4C4C', '#ff2929'],
						colors: ['#ff2929', '#FF4C4C', '#FF6E6E', '#FF9191', '#FFB3B3'],
						dataLabels: {
							enabled: true,
						},
					},
				},
				legend: {
					itemStyle: {
						textOutline: 'none'
					}
				},
			}
		}
	},
   computed: {
      ...mapState({
			listaDonosScript: state => state.listaDonosScript,
			listaPacotes: state => state.listaPacotes,
			listaScripts: state => state.listaScripts,
			urlRest: state => state.urlRest
      })
   },
	methods: {
		buscarVendasStatus : function () {
			this.buscarVendasPacotes()
			this.buscarVendasDias()

			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.status = {'resultado': [], 'vendaTotal': 0}

			this.$axios({
				method: 'post',
				url: this.urlRest +'pagamento/searchVendasByStatus',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.status = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: this.$t('sessaoExpirada')
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		buscarVendasDias : function () {
			this.pesquisa.dias = {'datas': [], 'valores': [], 'quantidades': []}
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'pagamento/searchVendasByDias',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
         }).then(response => {
				this.pesquisa.dias = response.data;
				this.atualizarGraficoPacotes()

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		buscarVendasPacotes : function () {
         this.pesquisa.produtos = []
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'pagamento/searchVendasByPacotes',
            headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

         }).then(response => {
				this.pesquisa.produtos = response.data
				this.atualizarGraficoPie()

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
		atualizarGraficoPacotes : function () {
			this.chartMaisVendidos.xAxis.categories = [];
			this.chartMaisVendidos.series[0].data = [];
			this.chartMaisVendidos.series[1].data = [];
			this.pesquisa.dias.quantidades.forEach(element => this.chartMaisVendidos.series[0].data.push(element));
			this.pesquisa.dias.valores.forEach(element => this.chartMaisVendidos.series[1].data.push(element));
			this.pesquisa.dias.datas.forEach(element => this.chartMaisVendidos.xAxis.categories.push(element));
		},
		atualizarGraficoPie : function () {
			this.chartPie.series[0].data = [];
			this.pesquisa.produtos.forEach(element => this.chartPie.series[0].data.push({'name': element.nomePacote, 'y': element.quantidadeVendas}));
		}
	},
	mounted() {
		this.$store.dispatch('buscarDonosScript')
      this.$store.dispatch('searchAllPacotes')
      this.$store.dispatch('searchAllScripts')
	}
}

</script>